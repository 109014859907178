import React from 'react'
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout'

import { GatsbyImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'

import SEO from '../components/seo'
import Utils from '../components/utils'

const Quote = ({ data, location, pageContext }) => {
  return (
    <Layout>

      <SEO
        pagetitle="新着名言一覧"
        pagedesc="新着名言一覧"
        pagepath={location.pathname}
      />


      <div className="l-wrap l-quote">
        <h2 className="m-heading">名言一覧</h2>
        <div className='m-quotes'>
          {data.allContentfulBlogPost.edges.map(( { node }) => {
            const num = Math.floor( Math.random() * Utils.color.length )
            return (
              <article className={`m-quotes__item ${Utils.color[num]}`} key={node.id}>
                <Link to={`/${node.category.slug}/${node.slug}/`}>
                  <h3 className="m-quotes__hdg">
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <span className="m-quotes__txt">{node.title}</span>
                  </h3>
                </Link>

                <dl className="m-quotes__details">
                  <Link to={`/person/${node.category.slug}/`}>
                    <dt className="m-quotes__img">
                      <figure>
                        <GatsbyImage
                          image={node.category.selfie.gatsbyImageData}
                          description={node.category.selfie.description}
                        />
                      </figure>
                    </dt>
                  </Link>
                  <Link to={`/person/${node.category.slug}/`}>
                    <dd className="m-quotes__name">{node.category.name}</dd>
                  </Link>
                </dl>
              </article>
            )
          })}
        </div>
      </div>

      <ul className="pagenation">
        {!pageContext.isFirst && (
          <li className="pagenation__item pagenation__item--prev">
            <Link
              to={
                pageContext.currentPage === 2
                  ? `/quote/`
                  : `/quote/${pageContext.currentPage - 1}`
              }
              rel="prev"
            >
              前のページへ
            </Link>
          </li>
        )}

        {!pageContext.isLast && (
          <li className="pagenation__item pagenation__item--next">
            <Link
              to={
                `/quote/${pageContext.currentPage + 1}/`
              }
              rel="next"
            >
              次のページへ
            </Link>
          </li>
        )}

      </ul>

    </Layout>

  )
}


export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: {fields: publishDate, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          category {
            slug
            name
            selfie {
              gatsbyImageData(layout: FULL_WIDTH)
              description
            }
          }
          eyecatch {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }
  }
`


export default Quote;